import CalculateIcon from "@mui/icons-material/Calculate";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import FeedIcon from "@mui/icons-material/Feed";
import InventoryIcon from "@mui/icons-material/Inventory";
import LayersIcon from "@mui/icons-material/Layers";
import PeopleIcon from "@mui/icons-material/People";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { SidebarNav } from "./components";

const drawerWidth = 240;

const Sidebar = (props) => {
  const { open, variant, onClose, onOpen } = props;
  const theme = useTheme();

  // Inventory pages
  const inventoryPages = [
    {
      title: "Stock In",
      href: "/admin/stockIn",
      icon: <InventoryIcon />,
      permission: "StockIn.View",
    },
    /* {
      title: "Disposal",
      href: "/admin/disposal",
      icon: <DeleteSweep />,
      permission: "Disposal.View",
    },
    {
      title: "Usage",
      href: "/admin/usage",
      icon: <LoopIcon />,
      permission: "Usage.View",
    },
    {
      title: "Sale",
      href: "/admin/sale",
      icon: <PointOfSaleIcon />,
      permission: "Sale.View",
    }, */
    {
      title: "Supplier",
      href: "/admin/suppliers",
      icon: <PeopleIcon />,
      permission: "Supplier.View",
    },
    {
      title: "Raw Material",
      href: "/admin/rawMaterials",
      icon: <LayersIcon />,
      permission: "RawMaterial.View",
    },
    {
      title: "UOM",
      href: "/admin/uom",
      icon: <CalculateIcon />,
      permission: "UOM.View",
    },
    /* {
      title: "RFID",
      href: "/admin/rfid",
      icon: <StyleIcon />,
      permission: "RFID",
    }, */
  ];

  // Traceability pages
  const traceabilityPages = [
    {
      title: "Monitor",
      href: "/admin/monitor",
      icon: <DashboardIcon />,
      permission: "Monitor",
    },
    {
      title: "Dashboard",
      href: "/admin/dashboard",
      icon: <DashboardIcon />,
      permission: "Dashboard",
    },
    {
      title: "Product",
      href: "/admin/products",
      icon: <StoreIcon />,
      permission: "Product.View",
    },
    {
      title: "Order",
      href: "/admin/orders",
      icon: <ShoppingCartIcon />,
      permission: "Order.View",
    },
    {
      title: "Production",
      href: "/admin/productions",
      icon: <PlaylistAddCheckIcon />,
      permission: "Production.View",
    },
    {
      title: "QR Design",
      href: "/admin/qrDesigns",
      icon: <DesignServicesIcon />,
      permission: "QRDesign.View",
    },
    {
      title: "Template",
      href: "/admin/templates",
      icon: <FeedIcon />,
      permission: "Template.View",
    },
    {
      title: "User",
      href: "/admin/users",
      icon: <PeopleIcon />,
      permission: "User",
    },
    {
      title: "Entity",
      href: "/admin/entities",
      icon: <StoreIcon />,
      permission: "Entity",
    },
  ];

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      variant={variant}
      PaperProps={{
        sx: {
          marginTop: "64px",
          height: "calc(100vh - 64px)",
          ...(open && {
            width: drawerWidth,
            whiteSpace: "nowrap",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
          ...(!open && {
            width: 70,
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }),
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
        }}
      >
        {/* Traceability Section */}
        <Typography variant="h6" style={{ marginTop: theme.spacing(4) }} textAlign={open ? "left" : "center"}>
          {open ? "Traceability" : "T"}
        </Typography>
        <Divider style={{ marginBottom: theme.spacing(1) }} />
        <SidebarNav pages={traceabilityPages} open={open} />

        {/* Inventory Section */}
        <Typography variant="h6" style={{ marginTop: theme.spacing(2) }} textAlign={open ? "left" : "center"}>
          {open ? "Inventory" : "I"}
        </Typography>
        <Divider style={{ marginBottom: theme.spacing(1) }} />
        <SidebarNav pages={inventoryPages} open={open} />

        <div
          style={{
            display: "flex",
            alignItems: open ? "flex-end" : "center",
            justifyContent: open ? "flex-end" : "center",
            marginTop: "auto", // Position the chevron at the bottom
            // padding: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}
        >
          {open ? (
            <IconButton onClick={onClose}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton onClick={onOpen}>
              <ChevronRightIcon />
            </IconButton>
          )}
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
